import { Component, Vue } from 'vue-property-decorator';
import { PayloadState } from '@/types/types';

@Component
export default class DialogMixins extends Vue {
  dialog(value: string) {
    return this.$store.state.dialogs[value];
  }

  async open(dialog: string, id?: number | string, modulePath?: string): Promise<void> {
    let payload: PayloadState = {
      idx: dialog,
      state: true,
    };

    if (id !== 0 && modulePath) {
      await this.$store.dispatch(modulePath, id);
    }
    await this.$store.dispatch('updateDialog', payload, { root: true });
    setTimeout(async () => {
      await this.$store.dispatch('isDialogLoading', false, { root: true });
    }, 500);
  }

  async close(payload: PayloadState): Promise<void> {
    await this.$store.dispatch('updateDialog', payload, { root: true });
    await this.$store.dispatch('isDialogLoading', false, { root: true });
  }
}
