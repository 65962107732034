/**
 * Filtering function for truncating a long string
 * @param value {String} - The string to truncate
 * @param length {Number} - The length of strings to retain
 * @param chars {String}
 */
const truncate = (value: string, length: number, chars: string) => {
  return value.length > length ? value.slice(0, length).trim() + chars : value;
};

export default truncate;
