/**
 * Filter function for getting character at specific position
 * @param value {String}
 * @param index {Number}
 * @returns {string|Error}
 */
const charAt = (value: string, index: number) => {
  return value.charAt(index).toUpperCase();
};

export default charAt;
